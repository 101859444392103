const DOMENICO = `const name = 'Domenico Lerario';
const email = 'domenico@mdma.com.br';

const experience = [
  {
    name: 'Blocknix',
    from: 'July 2017',
    to: 'present',
    position: 'Founder',
    tech: ['Blockchain', 'Crypto', 'JavaScript', 'NextJS', 'WordPress', 'PHP', 'mySQL']
  },
  {
    name: 'MDMA',
    from: 'July 2015',
    to: 'present',
    position: 'Founder',
    tech: ['Linux', 'PHP', 'WordPress', 'mySQL']
  },
  {
    name: 'Econobit',
    from: 'October 2013',
    to: 'present',
    position: 'Founder',
    tech: ['Blockchain', 'Crypto', 'TypeScript', 'React', 'Node', 'MSSQL']
  },
  {
    name: 'CoinDeal Derivatives (BBOD)',
    from: 'October 2018',
    to: 'January 2019',
    position: 'Brazil Country Head',
    tech: ['Crypto']
  },
  {
    name: 'Foxbit',
    from: 'February 2017',
    to: 'September 2017',
    position: 'Financial Aid and Project Development',
    tech: ['Crypto', 'Banking Services']
  },
  {
    name: 'MIR Assessoria Contábil e Financeira',
    from: 'January 2012',
    to: 'August 2015',
    position: 'Financial Aid and Administrative Assistant',
    tech: ['Tax Services', 'MATLAB']
  },
];

export default domenico
`;

export default DOMENICO;

import { FC } from 'react';

const Settings: FC = () => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 351 348"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.75 46.2C97.0591 46.2 105.987 49.445 112.569 55.2211C119.152 60.9972 122.85 68.8313 122.85 77C122.85 85.1687 119.152 93.0028 112.569 98.7789C105.987 104.555 97.0591 107.8 87.75 107.8C78.4409 107.8 69.5131 104.555 62.9305 98.7789C56.348 93.0028 52.65 85.1687 52.65 77C52.65 68.8313 56.348 60.9972 62.9305 55.2211C69.5131 49.445 78.4409 46.2 87.75 46.2ZM263.25 0C286.523 0 308.842 8.11248 325.299 22.5528C341.755 36.9931 351 56.5783 351 77C351 97.4217 341.755 117.007 325.299 131.447C308.842 145.888 286.523 154 263.25 154H87.75C64.4773 154 42.1577 145.888 25.7014 131.447C9.24506 117.007 0 97.4217 0 77C0 56.5783 9.24506 36.9931 25.7014 22.5528C42.1577 8.11248 64.4773 0 87.75 0H263.25ZM87.75 30.8C73.7864 30.8 60.3946 35.6675 50.5208 44.3317C40.647 52.9958 35.1 64.747 35.1 77C35.1 89.253 40.647 101.004 50.5208 109.668C60.3946 118.333 73.7864 123.2 87.75 123.2H263.25C277.214 123.2 290.605 118.333 300.479 109.668C310.353 101.004 315.9 89.253 315.9 77C315.9 64.747 310.353 52.9958 300.479 44.3317C290.605 35.6675 277.214 30.8 263.25 30.8H87.75Z"
        fill="white"
        stroke="currentColor"
      ></path>
      <path
        d="M263.25 194H87.75C64.4773 194 42.1577 202.112 25.7014 216.553C9.24506 230.993 0 250.578 0 271C0 291.422 9.24506 311.007 25.7014 325.447C42.1577 339.888 64.4773 348 87.75 348H263.25C286.523 348 308.842 339.888 325.299 325.447C341.755 311.007 351 291.422 351 271C351 250.578 341.755 230.993 325.299 216.553C308.842 202.112 286.523 194 263.25 194V194ZM263.25 317.2C249.286 317.2 235.895 312.333 226.021 303.668C216.147 295.004 210.6 283.253 210.6 271C210.6 258.747 216.147 246.996 226.021 238.332C235.895 229.667 249.286 224.8 263.25 224.8C277.214 224.8 290.605 229.667 300.479 238.332C310.353 246.996 315.9 258.747 315.9 271C315.9 283.253 310.353 295.004 300.479 303.668C290.605 312.333 277.214 317.2 263.25 317.2Z"
        fill="white"
        stroke="currentColor"
      ></path>
    </svg>
  );
};

export default Settings;

import formatDateForFinder from '../utils/formatDateForFinder';
import { domenico } from './Files/';
import { mucio } from './Files/';

export type Contents = Record<string, File> | string;
type FileType = 'file' | 'directory' | 'html' | 'JavaScript script';
export interface File {
  fileSize?: number;
  fileType: FileType;
  display: string;
  searchText: string[];
  contents: Contents;
  created?: string;
  modified?: string;
  lastOpened?: string;
  previewImageSrc?: string;
}
const fileDirectory: Record<string, File> = {
  '/': {
    display: '/',
    fileType: 'directory',
    searchText: ['/'],
    contents: {
      home: {
        display: 'home',
        fileType: 'directory',
        searchText: ['home'],
        contents: {
          partners: {
            display: 'partners',
            fileType: 'directory',
            searchText: ['partners'],
            contents: {
              'Domenico.js': {
                display: 'Domenico.js',
                fileType: 'JavaScript script',
                contents: domenico,
                searchText: ['Domenico', 'js'],
                created: formatDateForFinder(
                  new Date('April 1, 2000 04:20:00')
                ),
                modified: formatDateForFinder(
                  new Date('April 1, 2000 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('April 1, 2000 04:20:00')
                ),
                previewImageSrc: 'resume-preview.png',
                fileSize: 10,
              },
              'Mucio.js': {
                display: 'Mucio.js',
                fileType: 'JavaScript script',
                contents: mucio,
                searchText: ['Mucio', 'js'],
                created: formatDateForFinder(
                  new Date('April 1, 2000 04:20:00')
                ),
                modified: formatDateForFinder(
                  new Date('April 1, 2000 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('April 1, 2000 04:20:00')
                ),
                previewImageSrc: 'resume-preview.png',
                fileSize: 10,
              },
            },
          },
          projects: {
            fileType: 'directory',
            display: 'projects',
            searchText: ['projects'],
            contents: {
              'econobit.html': {
                display: 'econobit.html',
                fileType: 'html',
                contents: `https://econobit.io/`,
                searchText: ['econobit'],
                created: formatDateForFinder(
                  new Date('October 29, 2013 07:33:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'econobit-preview.png',
                fileSize: 20,
              },
              'blocknix.html': {
                display: 'blocknix.html',
                fileType: 'html',
                contents: `https://blocknix.com`,
                searchText: ['blocknix'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'ourobit.html': {
                display: 'ourobit.html',
                fileType: 'html',
                contents: `https://ourobit.com.br/`,
                searchText: ['ourobit'],
                created: formatDateForFinder(
                  new Date('September 10, 2017 08:25:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'ourobit-preview.png',
                fileSize: 20,
              },
              'ebrl.html': {
                display: 'ebrl.html',
                fileType: 'html',
                contents: `https://ebrl.com.br/`,
                searchText: ['ebrl'],
                created: formatDateForFinder(
                  new Date('August 31, 2020 09:41:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'ebrl-preview.png',
                fileSize: 20,
              },
            },
          },
          clients: {
            fileType: 'directory',
            display: 'clients',
            searchText: ['clients'],
            contents: {
              'firmafortebrasil.html': {
                display: 'firmafortebrasil.html',
                fileType: 'html',
                contents: `https://firmafortebrasil.com.br/`,
                searchText: ['firmafortebrasil', 'firma', 'forte', 'brasil', ],
                created: formatDateForFinder(
                  new Date('October 29, 2013 07:33:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'travelandvacationclub.html': {
                display: 'travelandvacationclub.html',
                fileType: 'html',
                contents: `https://travelandvacationclub.com`,
                searchText: ['travelandvacationclub', 'travel', 'vacation', 'club'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'vouparaocaribe.html': {
                display: 'vouparaocaribe.html',
                fileType: 'html',
                contents: `https://vouparaocaribe.com.br`,
                searchText: ['vouparaocaribe', 'vou', 'para', 'caribe'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'vouparaorlando.html': {
                display: 'vouparaorlando.html',
                fileType: 'html',
                contents: `https://vouparaorlando.com.br`,
                searchText: ['vouparaorlando', 'vou', 'para', 'orlando'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'superestrategico.html': {
                display: 'superestrategico.html',
                fileType: 'html',
                contents: `https://superestrategico.com.br`,
                searchText: ['superestrategico', 'super', 'estrategico'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'youniquejoias.html': {
                display: 'youniquejoias.html',
                fileType: 'html',
                contents: `https://loja.youniquejoias.com.br`,
                searchText: ['youniquejoias', 'younique', 'joias'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'osupercerebro.html': {
                display: 'youniqueosupercerebrojoias.html',
                fileType: 'html',
                contents: `https://osupercerebro.com.br`,
                searchText: ['osupercerebro', 'super', 'cerebro'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'liw.honsha.html': {
                display: 'liw.honsha.html',
                fileType: 'html',
                contents: `https://liw.honsha.org`,
                searchText: ['liw', 'honsha'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'lomatransportes.html': {
                display: 'lomatransportes.html',
                fileType: 'html',
                contents: `https://lomatransportes.com.br`,
                searchText: ['loma', 'transportes'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'dentaly.html': {
                display: 'dentaly.html',
                fileType: 'html',
                contents: `https://dentaly.com.br`,
                searchText: ['dentaly'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'loja.binaural.html': {
                display: 'loja.binaural.html',
                fileType: 'html',
                contents: `https://loja.binaural.com.br`,
                searchText: ['loja.binaural', 'loja', 'binaural'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'vernizsp.html': {
                display: 'vernizsp.html',
                fileType: 'html',
                contents: `https://vernizsp.com.br`,
                searchText: ['verniz'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'paulopjota.html': {
                display: 'paulopjota.html',
                fileType: 'html',
                contents: `https://paulopjota.com`,
                searchText: ['paulo', 'pj', 'pjota'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              },
              'multitecgesso.html': {
                display: 'multitecgesso.html',
                fileType: 'html',
                contents: `https://multitecgesso.com.br`,
                searchText: ['multitecgesso', 'multitec', 'multi', 'gesso'],
                created: formatDateForFinder(
                  new Date('July 14, 2017 03:24:00')
                ),
                modified: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                lastOpened: formatDateForFinder(
                  new Date('July 4, 2022 04:20:00')
                ),
                previewImageSrc: 'blocknix-preview.png',
                fileSize: 20,
              }, 
            },
          },
        },
      },
    },
  },
};

export default fileDirectory;

const MUCIO = `const name = 'Mucio Tabachini';
const email = 'mucio@mdma.com.br';

const experience = [
  {
    name: 'Blocknix',
    from: 'July 2017',
    to: 'present',
    position: 'Co-founder',
    tech: ['Marketing']
  },
  {
    name: 'Econobit',
    from: 'January 2017',
    to: 'present',
    position: 'Co-founder',
    tech: ['Marketing', 'Customer Service and Planning']
  },
  {
    name: 'MDMA',
    from: 'July 2015',
    to: 'present',
    position: 'Founder',
    tech: ['Marketing', 'Customer Service', 'Sales', 'Planning']
  },
  {
    name: 'IBS Digital',
    from: 'February 2013',
    to: 'August 2015',
    position: 'Digital Marketing Manager',
    tech: ['Paid Media', 'Social Media', 'Business Inteligence', 'SEO']
  },
  {
    name: 'Azza Digital',
    from: 'January 2012',
    to: 'June 2012',
    position: 'Media Manager',
    tech: ['Google Ads', 'Google Analytics', 'Facebook']
  },
  {
    name: 'Elogia',
    from: 'July 2011',
    to: 'December 2011',
    position: 'E-Commerce and Social Ads Analyst',
    tech: ['Google Ads', 'Google Analytics', 'Facebook']
  },
  {
    name: 'ImovelWEB',
    from: 'March 2011',
    to: 'June 2011',
    position: 'E-Commerce and Social Ads Analyst',
    tech: ['Google Ads', 'Google Analytics', 'Facebook']
  },
  {
    name: 'WEBTraffic',
    from: 'April 2007',
    to: 'March 2011',
    position: 'Coordinator SEM, SEO e E-Commerce',
    tech: ['Google Ads', 'Google Analytics']
  },
];

export default mucio
`;

export default MUCIO;





import { FC } from 'react';

const Moon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-moon"
      strokeWidth="0"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="2 1.99 19.31 20"
      width="18"
      height="18"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path fill="white" d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" />
    </svg>
  );
};

export default Moon;

import { WindowConfig } from './';

const config: WindowConfig = {
  width: 530,
  height: 320,
  resizeable: false,
};

export default config;



